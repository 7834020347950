<template>
	<page-section>
		<div
			:class="['xs', 'sm'].includes(windowWidth) ? '' : 'mx-5'"
			class="my-4">
			<div class="row row-eq-height justify-content-around">
				<div
					v-for="(product, productIndex) in products"
					:key="`catalog-item-${productIndex}`"
					:class="{
						'mb-4': ['xs', 'sm'].includes(windowWidth) && productIndex !== products.length - 1,
						'col-md-3': products.length === 4,
						'col-md-4': products.length <= 3,
					}"
					class="col-12 d-flex flex-column">
					<div class="row justify-content-center mb-0">
						<div class="col d-flex justify-content-center">
							<img
								class="img-fluid w-100"
								style="max-width: 80vw;"
								:src="product.image">
						</div>
					</div>
					<div class="row justify-content-center mb-3">
						<div class="col-auto text-center">
							<h2
								class="section-header text-primary mt-3 text-center m-0"
								style="max-width: 300px;">
								{{ product.name }}
							</h2>
						</div>
					</div>
					<div class="row justify-content-center mb-4">
						<div class="col-auto text-center px-4">
							<span
								class="mt-3 text-center text-gray"
								style="max-width: 300px;">
								{{ product.description }}
							</span>
						</div>
					</div>
					<div class="row justify-content-center mt-auto">
						<div class="col text-center">
							<!-- :href="product.url" -->
							<div
								v-b-modal="'catalog-video'"
								target="_blank"
								:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'w-75'"
								class="btn btn-lg text-white pointer rounded-0 py-2"
								:style="makeHighlightStyle('background-color')"
								@click="currentVideoUrl = product.url[language]">
								<div :class="ctaClasses">
									{{ translate('learn_more') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			id="catalog-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="currentVideoUrl"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Color from '../mixins/Color';
import PageSection from './PageSection';

export default {
	name: 'CatalogSection',
	components: {
		PageSection,
	},
	mixins: [Color, WindowSizes],
	props: {
		products: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			currentVideoUrl: null,
		};
	},
	computed: {
		ctaClasses() {
			const classes = [
				'font-weight-bolder h6 my-2',
				['xs', 'sm'].includes(this.windowWidth) ? 'w-100' : 'mx-auto',
				this.language !== 'es' ? ' text-capitalize' : '',
			];

			return classes.join(' ');
		},
	},
};
</script>
